<template>
  <div>
    <section class="privacy-policy-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>개인정보처리방침 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>개인정보처리방침 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">개인정보처리방침 <br />내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
          </table>
          <!-- /.table-->

          <router-link
            :to="{ name: 'PrivacyPolicyList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>

          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "PrivacyPolicyEdit",
  data() {
    return {
      title: this.item != null ? this.item.title : "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  created() {
    console.log("");
    this.title = this.item.title;
    this.editorData = this.item.contents;

  },
  methods: {
    onSubmitModify() {
      console.log("onSubmitModify => ", this.editorData);
      this.$store
        .dispatch("others/req_update_privacypolicy", {
          id: this.item.id, 
          contents: this.editorData,
          title: this.title,
        })
        .then(result => {
          console.log("req_update_privacypolicy result => ", result);
          this.$alert("수정하였습니다.").then(() => {
            this.$router.push({ name: "PrivacyPolicyList" });
          });
        })
        .catch(err => {
          console.log(" error => ", err);
        });
    },
  },
};
</script>


<style scoped>
.ck.ck-editor__editable_inline {
  height: 400px !important;
  /* max-height: 400px; */
}
</style>
