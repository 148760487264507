<template>
  <div>
    <section class="popup-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>팝업 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>팝업 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">기간</label>
              </th>
              <td class="popup-date">
                <div class="first-date">
                  <date-picker
                    v-model="event_periods"
                    valueType="format"
                    format="YYYY.MM.DD"
                    range
                    placeholder="시작일 ~ 종료일"
                    :lang="lang"
                  ></date-picker>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">이미지</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select
                  v-model="fileMain"
                  v-bind:show-del="
                    this.fileMain != null && this.fileMain.name != null
                  "
                ></file-select>
                <span
                  >사이즈 : 400 x 500px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="video-use"
                    name="video"
                    value="1"
                    v-model="status"
                  />
                  <label for="video-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="video-unuse"
                    name="video"
                    value="0"
                    v-model="status"
                  />
                  <label for="video-unuse">미사용</label>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="url">URL</label>
              </th>
              <td>
                <input type="text" id="url" name="url" v-model="url" />
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'PopupList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../..//FileSelect.vue";
import attachments_update_mixin from "../../../mixins/attachments_update_mixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect, DatePicker },
  name: "PopupEdit",
  mixins: [attachments_update_mixin],
  data() {
    return {
      title: "",
      url: "",
      status: 1,

      event_periods: null,
      lang: null,
    };
  },
  created() {
    console.log("props id => ", this.id);
    console.log("props event => ", this.item);
    this.lang = this.datepicker_lang;

    this.title = this.item.title;
    this.url = this.item.url;
    this.status = this.item.status;

    this.event_periods = [this.item.start_day, this.item.end_day];

    this.attachments =
      this.item.attachment != null ? [this.item.attachment] : [];
    console.log("attachments => ", this.attachments);

    this.init_attachments_set();

    // this.attachment = this.item.attachment ;
    // if( this.attachment != null ) {
    //   this.fileMain = {
    //     name : this.item.attachment.originalname
    //   }
    // }
  },
  methods: {
    onSubmitModify() {
      console.log("onSubmitModify. -----");

      if (
        this.event_periods == null ||
        this.event_periods.length != 2 ||
        this.event_periods[0] == null ||
        this.event_periods[1] == null
      ) {
        return this.$fire({
          title: "이벤트 기간을 선택해주세요",
          text: "",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      var start_day = this.event_periods[0];
      var end_day = this.event_periods[1];

      const payload = {
        id: this.item.id,
        title: this.title,
        start_day: start_day,
        end_day: end_day,
        status: this.status,
        url: this.url,
      };

      this.$store
        .dispatch("others/req_update_popup", payload)
        .then(() => {
          this.onSubmitModifyFiles("popup", "PopupList");

          // this.$alert("수정하였습니다.").then( () => {
          //   this.$router.push({ name: "PopupList" });
          // });
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  min-width: 1530px;
}
table {
  tr {
    td {
      &.popup-date {
        display: flex;
      }
      select {
        width: 6vw !important;
        min-width: 90px;
      }
      label {
        margin-right: 13px;
      }
      em {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
}
</style>
