<template>
  <div>
    <section class="davich-number-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>숫자로 보는 다비치 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>숫자로 보는 다비치 관리</span>
          </p>

          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">가맹점수</th>
                <th scope="col">사관·공채졸업생</th>
                <th scope="col">안경기부 데이터 관리</th>
                <th scope="col">고객 수</th>
                <th scope="col">봉사활동(사회공헌)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label for=""></label>
                  <input
                    type="text"
                    name=""
                    v-model="franchise_count"
                    id=""
                  />개
                </td>
                <td>
                  <label for=""></label>
                  <input type="text" name="" v-model="graduate_count" id="" />명
                </td>
                <td>
                  <label for=""></label>
                  <input
                    type="text"
                    name=""
                    v-model="glasses_give_count"
                    id=""
                  />개
                </td>
                <td>
                  <label for=""></label>
                  <input type="text" name="" v-model="customer_count" id="" />만
                </td>
                <td>
                  <label for=""></label>
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="volunteer_count"
                  />회
                </td>
              </tr>
            </tbody>
          </table>

          <a class="btn-blue btn-right" @click="onSubmitModify">수정</a>
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  components: { AppFooter },
  name: "DavichNumberEdit",
  data() {
    return {};
  },
  computed: {
    franchise_count: {
      set(val) {
        if (val != null) {
          this.$store.commit("others/SET_FRANCHISE_COUNT", {
            franchise_count: val,
          });
        } else {
          this.$store.commit("others/SET_FRANCHISE_COUNT", {
            franchise_count: 0,
          });
        }
      },
      get() {
        return this.$store.state.others.franchise_count;
      },
    },
    graduate_count: {
      set(val) {
        if (val != null) {
          this.$store.commit("others/SET_GRADUATE_COUNT", {
            graduate_count: val,
          });
        } else {
          this.$store.commit("others/SET_GRADUATE_COUNT", {
            graduate_count: 0,
          });
        }
      },
      get() {
        return this.$store.state.others.graduate_count;
      },
    },
    glasses_give_count: {
      set(val) {
        if (val != null) {
          this.$store.commit("others/SET_GLASSES_COUNT", {
            glasses_give_count: val,
          });
        } else {
          this.$store.commit("others/SET_GLASSES_COUNT", {
            glasses_give_count: 0,
          });
        }
      },
      get() {
        return this.$store.state.others.glasses_give_count;
      },
    },
    customer_count: {
      set(val) {
        if (val != null) {
          this.$store.commit("others/SET_CUSTOMER_COUNT", {
            customer_count: val,
          });
        } else {
          this.$store.commit("others/SET_CUSTOMER_COUNT", {
            customer_count: 0,
          });
        }
      },
      get() {
        return this.$store.state.others.customer_count;
      },
    },
    volunteer_count: {
      set(val) {
        if (val != null) {
          this.$store.commit("others/SET_VOLUNTEER_COUNT", {
            volunteer_count: val,
          });
        } else {
          this.$store.commit("others/SET_VOLUNTEER_COUNT", {
            volunteer_count: 0,
          });
        }
      },
      get() {
        return this.$store.state.others.volunteer_count;
      },
    },
  },
  created() {
    this.$store.dispatch("others/getDavichNumbers");
  },
  methods: {
    onSubmitModify() {
      console.log("onSubmitModify : ", this.volunteer_count);
      this.$store
        .dispatch("others/updateDavichNumbers", {
          franchise_count: this.franchise_count,
          graduate_count: this.graduate_count,
          glasses_give_count: this.glasses_give_count,
          customer_count: this.customer_count,
          volunteer_count: this.volunteer_count,
        })
        .then((res) => {
          this.$store.commit("others/SET_DAVICH_NUMBERS", res.data);
          this.$alert("수정하였습니다.").then(() => {});
        })
        .catch((error) => {
          this.$alert("수정에 실패하였습니다.", error).then(() => {});
          console.log("error => ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.davich-number-edit {
  table {
    colgroup {
      .col1,
      .col2,
      .col3,
      .col4,
      .con5 {
        width: 20%;
      }
    }
  }
  td {
    padding: 14px 15px;
    text-align: center;
    input {
      display: inline-block;
      max-width: 305px;
      width: 80%;
      height: 50px;
      border: 1px solid #ccc;
      margin-right: 13px;
      text-align: center;
    }
  }
}
</style>
