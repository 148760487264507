<template>
  <div>
    <section class="admin-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>관리자 계정 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>관리자 계정 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="admin-level">레벨/권한</label>
              </th>
              <td>
                <span>

                  <select
                    name="admin-level"
                    id="admin-level"
                    v-model="admin_group"
                  >
                    <option
                      v-for="item in admin_groupList"
                      v-bind:key="item.id"
                      :value="item"
                    >
                      {{ item.id + ' / ' + item.team_name  }}
                    </option>
                  </select>

                </span>
              </td>
            </tr>

            <tr>
              <th scope="row"><label for="admin-id">아이디</label></th>
              <td class="admin-id">
                <input type="text" name="admin-id" id="admin-id" v-model="admin_id"/>
                <button @click="checkID">중복확인</button>
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="admin-password">비밀번호</label></th>
              <td>
                <input
                  type="password"
                  name="admin-password"
                  id="admin-password"
                  v-model="admin_pw"
                />
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="admin-name">이름</label></th>
              <td>
                <input type="text" name="admin-name" id="admin-name" v-model="name"/>
              </td>
            </tr>
            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="video-use"
                    name="video"
                    value=1
                    v-model="status"
                  />
                  <label for="video-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="video-unuse"
                    name="video"
                    value=0
                    v-model="status"
                  />
                  <label for="video-unuse">미사용</label>
                </span>
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'AdminList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right"  @click="onClickCreate">저장</button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  components: { AppFooter },
  name: "AdminCreate",
  data() {
    return {
      admin_groupList: [],
      admin_group: null ,

      is_id_checked: false , 

      admin_id : '',
      admin_pw : '',
      name : '',
      status: 1, 
    }
  },
  created() {
    
    this.$store.dispatch("others/req_admingroup_list"  )
    .then(( ret ) => {
      this.admin_groupList = ret.data ; 
      console.log( 'req_admingroup_list ret => ',this.admin_groupList   ); 
      this.admin_group = this.admin_groupList[0]; 
    })
    .catch((error) => {
      console.log("error => ", error);
    });
  },
  methods: {
    checkID() {

      if( this.admin_id == null || this.admin_id == undefined || this.admin_id.length <= 0 ) {
        return ; 
      }
      const payload = {
        admin_id: this.admin_id 
      }; 

      this.$store.dispatch("others/req_find_admin" , payload  )
      .then(( ret ) => {
        console.log( 'req_find_admin ret => ',ret  ); 
        if( ret.length > 0  ) {
          // 이미 사용중인 아이디.
          this.$alert("아이디가 이미 사용중입니다.");
          this.is_id_checked = false  ; 

        }
        else {
          // 사용가능함 
          this.$alert("사용가능합니다.");
          this.is_id_checked = true ; 
        }

      })
      .catch((error) => {
        console.log("error => ", error);
      });

    },
    onClickCreate() {
      console.log('onClickCreate '); 

      if( !this.is_id_checked ) {
        this.$alert("아이디 중복확인이 필요합니다.").then( () => {
        });
        return ; 
      }

      if( this.name && this.name.length <= 0 ) {
        this.$alert("이름을 입력해 주세요.").then( () => {
        });
        return ; 
      }


      var payload = {
        auth_group: this.admin_group.id , 
        admin_id: this.admin_id ,
        name: this.name,
        status: this.status ,
      };


      if( this.admin_pw != null && this.admin_pw != undefined && this.admin_pw.length > 0 ) {
        payload.admin_pw =  this.admin_pw ; 
      }
      else {
        this.$alert("비밀번호를 입력해 주세요.").then( () => {
        });
        return ; 
      }

      if( this.name.length <= 0 ) {
        this.$alert("이름을 입력해 주세요.").then( () => {
        });
        return ; 
      }



      console.log("in onClickSubmit ==>  ", payload );

      this.$store.dispatch("others/req_create_admin",  payload  )
      .then(( ) => {
        this.$alert("관리자 계정을 생성하였습니다.").then( () => {
            this.$router.push({ name: "AdminList" });
        });
      })
      .catch((error) => {
        console.log("error => ", error);
      });


    }

  },
};
</script>
<style scoped>
select,
input[id="admin-id"],
input[id="admin-password"],
input[id="admin-name"] {
  width: 230px !important;
}
.admin-id {
  display: flex;
}
button {
  width: 110px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border: 1px solid black;
  margin-right: 20px;
}
</style>
