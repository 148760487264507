<template>
  <div>
    <section class="privacy-policy-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>개인정보처리방침 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>개인정보처리방침 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">개인정보처리방침 <br />내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'PrivacyPolicyList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: { AppFooter },
  name: "PrivacyPolicyCreate",
  data() {
    return {
      title: "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  methods: {
    onClickCreate() {
      console.log("in onClickCreate ==>  title ", this.title);
      console.log("in onClickCreate ==>  editorData ", this.editorData);

      if( this.title == null || this.title.length <= 0 ) {
        return this.$fire({
          title: "제목을 입력해주세요",
          text: "",
          type: "warning",
          timer: 2000
        }).then(r => {
          console.log(r.value);
        });
      }

      if( this.editorData == null || this.editorData.length <= 0 ) {
        return this.$fire({
          title: "내용을 입력해주세요",
          text: "",
          type: "warning",
          timer: 2000
        }).then(r => {
          console.log(r.value);
        });
      }

      this.$store.dispatch("others/req_create_privacypolicy", {
        title: this.title ,
        contents: this.editorData
      }).then( result => {
        console.log('result =>', result );
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "PrivacyPolicyList" });
          });

      }).catch( error => {
        console.log('error => ', error ); 
      });


    },
  },
};
</script>
