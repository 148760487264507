<template>
  <div>
    <section class="admin-history-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>관리자 히스토리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>관리자 히스토리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">전체 게시물 <span>{{ getDataTotalCount }}</span>개</p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">아이디</th>
                <th scope="col">내역</th>
                <th scope="col">설정일</th>
              </tr>
            </thead>

            <tbody>

              <tr v-for="item in getData" v-bind:key="item.id">
                <td>{{item.id}}</td>
                <td>{{item.admin_id}}({{item.name}})</td>
                <td class="left">
                  {{item.message}}
                </td>
                <td>
                  {{ $moment(item.created_dttm).format("YYYY.MM.DD HH:mm:ss") }}
                </td>
              </tr>

            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";


export default {
  components: { AppFooter, Paginate, SearchInput ,  },
  name: "AdminHistoryList",
  data() {
    return {
      data_list: [ ] ,
      data_total_count: 0 ,
    };
  },
  computed: {
    getData: function() {
      return this.data_list ; 
    },
    getDataTotalCount: function() {
      return this.data_total_count ; 
    },
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    })
  },
  methods: {
    loadData( payload ) {
      this.$store.dispatch("others/req_admin_action_log_list", payload ).then( result => {
        console.log("req_admin_action_log_list result => ", result);
        this.data_list = result.data ; 
        this.data_total_count = result.total_count  ;
      }).catch( error => {
        console.log("error => ", error);
      });
    },
    onSearch(text) {
      console.log('text =>', text);
      this.searchtext = text ; 
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.col1 {
  width: 64px;
}
.col2 {
  width: 260px;
}
.col3 {
  width: calc(100% - 64px - 260px - 300px);
}
.col4 {
  width: 300px;
}
table {
  td {
    padding: 14px 15px;
  }
}
</style>
