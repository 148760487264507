<template>
  <div>
    <section class="popup-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>팝업 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>팝업 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">기간</label>
              </th>
              <td class="popup-date">
                <div class="first-date">
                  <date-picker
                    v-model="event_periods"
                    valueType="format"
                    format="YYYY.MM.DD"
                    range
                    placeholder="시작일 ~ 종료일"
                    :lang="lang"
                  ></date-picker>
                </div>

                <div class="last-date"></div>
              </td>
            </tr>
            <tr>
              <th scope="row">이미지</th>
              <td class="attached-file file-cell">
                <p v-if="filePopup">{{ filePopup.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="filePopup"></file-select>
                <span
                  >사이즈 : 400 x 500px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="video-use"
                    name="video"
                    value="1"
                    v-model="status"
                  />
                  <label for="video-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="video-unuse"
                    name="video"
                    value="0"
                    v-model="status"
                  />
                  <label for="video-unuse">미사용</label>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="url">URL</label>
              </th>
              <td>
                <input type="text" id="url" name="url" v-model="url" />
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'PopupList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../..//FileSelect.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { AppFooter, FileSelect, DatePicker },
  name: "PopupCreate",
  data() {
    return {
      filePopup: null,

      title: "",
      url: "",
      status: 1,

      event_periods: null,
      lang: null,
    };
  },
  created() {
    this.lang = this.datepicker_lang;
  },
  methods: {
    onClickCreate() {
      console.log("in onClickCreate ==>  title ", this.title);

      if (
        this.event_periods == null ||
        this.event_periods.length != 2 ||
        this.event_periods[0] == null ||
        this.event_periods[1] == null
      ) {
        return this.$fire({
          title: "기간을 선택해주세요",
          text: "",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      var start_day = this.event_periods[0];
      var end_day = this.event_periods[1];

      let formData = new FormData();

      formData.append("title", this.title);
      formData.append("url", this.url);
      formData.append("status", this.status);

      formData.append("start_day", start_day);
      formData.append("end_day", end_day);

      if (this.filePopup != null) formData.append("file", this.filePopup);

      this.$store
        .dispatch("others/req_create_popup", formData)
        .then((result) => {
          console.log("req_popup_event result => ", result);
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "PopupList" });
          });
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
  },
  updated() {},
};
</script>

<style lang="scss">
.popup-create,
.popup-edit {
  table {
    tr {
      td {
        &.popup-date {
          display: flex;
        }
        select {
          width: 5.8vw !important;
          padding-left: 7px !important;
          min-width: 73px;
          & + label {
            display: inline-block;
            margin-left: 5px;
            margin-right: 13px;
            flex-shrink: 0;
          }
          &#popup-date,
          &#popup-month {
            width: 4.2vw !important;
            min-width: 60px;
          }
        }

        em {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
