<template>
  <div>
    <section class="privacy-policy-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>개인정보처리방침 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>개인정보처리방침 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">제목</th>
                <th scope="col">등록일</th>
              </tr>
            </thead>
            <tbody>


              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">게시판 체크</label
                  ><input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>{{ item.id }}</td>
                <td class="cont-cell">
                  <router-link
                    :to="{
                      name: 'PrivacyPolicyEdit',
                      params: { id: item.id, item: item },
                    }"
                    class="of-hidden"
                    >{{ item.title }}
                  </router-link>
                </td>
                <td>
                  {{ $moment(item.created_dttm).format("YYYY.MM.DD") }}
                </td>
              </tr>

            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="@/assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link
              :to="{ name: 'PrivacyPolicyCreate' }"
              tag="a"
              class="btn-blue"
              >등록
            </router-link>
            <!-- /.btn -->
          </div>
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "PrivacyPolicyList",
  data() {
    return {
      data_list: [ ] ,
      data_total_count: 0 ,
    }
  },
  computed: {
    getData: function() {
      return this.data_list ; 
    },
    getDataTotalCount: function() {
      return this.data_total_count ; 
    }, 
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    }) ;
  },
  methods: {
    loadData(payload) {
      this.$store.dispatch("others/req_privacypolicyList", payload).then( result => {
        console.log('result =>', result );
        this.data_list = result.data ; 
        this.data_total_count = result.total_count  ; 
      }).catch( error => {
        console.log('error => ', error ); 
      });
    },
    onSearch(text) {
      this.searchtext = text;
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store.dispatch("others/req_remove_privacypolicy_list", {
            idarr: this.checkedValues,
          })
          .then((result) => {
            this.checkedValues = [];
            this.data_list = result.data ; 
            this.data_total_count = result.total_count  ; 
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  .col1 {
    width: 64px;
  }
  .col2 {
    width: 106px;
  }
  .col4 {
    width: 200px;
  }
}
</style>
