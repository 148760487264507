<template>
  <div>
    <section class="admin-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>관리자 계정 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>관리자 계정 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">전체 게시물 <span>{{ getDataTotalCount }}</span>개</p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
              <col class="col7" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label>
                  <input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">이름</th>
                <th scope="col">아이디</th>
                <th scope="col">레벨</th>
                <th scope="col">사용여부</th>
                <th scope="col">등록일</th>
              </tr>
            </thead>

            <tbody>


              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">관리자계정 체크</label>
                  <input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEdit', params: { id: item.id, item: item } }">{{item.id}}</router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEdit', params: { id: item.id, item: item } }">{{item.name}}</router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEdit', params: { id: item.id, item: item } }">{{item.admin_id}}</router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEdit', params: { id: item.id, item: item } }">{{item.auth_group}}</router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEdit', params: { id: item.id, item: item } }">{{ item.status == 1 ? "O": "X" }}</router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEdit', params: { id: item.id, item: item } }"
                    >
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD") }}

                    </router-link
                  >
                </td>
              </tr>



            </tbody>     
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link :to="{ name: 'AdminCreate' }" class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "AdminList",
  data() {
    return {
      data_list: [ ] ,
      data_total_count: 0 ,
    };
  },
  computed: {
    getData: function() {
      return this.data_list ; 
    },
    getDataTotalCount: function() {
      return this.data_total_count ; 
    },
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    }) ;
  },   
  methods: {
    loadData( payload ) {
      this.$store.dispatch("others/req_admin_list", payload ).then( result => {
        console.log( 'req_admin_list result => ', result );
        this.data_list = result.data ; 
        this.data_total_count = result.total_count  ; 
      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },
    onSearch(text) {
      this.searchtext = text ;
      this.loadData( {
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext ,
      }); 
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData( {
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext , 
      }); 
    },
    onSubmitDelete()  {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {        
        this.$store.dispatch("others/req_remove_admin_list", {
          idarr: this.checkedValues,
        }).then( (result) => {
          this.checkedValues = [];
          this.data_list = result.data ; 
          this.data_total_count = result.total_count  ; 
        });
      }
    },


  },
};
</script>
<style scoped>
.col1 {
  width: 64px;
}
</style>
