<template>
  <div>
    <section class="main-visual-contents-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>메인 비주얼 동영상 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>메인 비주얼 동영상 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">부제</label>
              </th>
              <td>
                <input
                  type="text"
                  id="sub-title"
                  name="sub-title"
                  v-model="subtitle"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">PC 동영상 파일 첨부</th>
              <td class="attached-file file-cell">
                <p v-if="filePc">{{ filePc.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="filePc"></file-select>
                <span>사이즈 : 1920 X 1280px / 용량 50MB (mp4) </span>
              </td>
            </tr>
            <tr>
              <th scope="row">MOBILE 동영상 파일 첨부</th>
              <td class="attached-file file-cell">
                <p v-if="fileMobile">{{ fileMobile.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMobile"></file-select>
                <span>사이즈 : 720 X 1040px / 용량 50MB (mp4) </span>
              </td>
            </tr>

            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="video-use"
                    name="video"
                    value="1"
                    v-model="status"
                  />
                  <label for="video-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="video-unuse"
                    name="video"
                    value="0"
                    v-model="status"
                  />
                  <label for="video-unuse">미사용</label>
                </span>
                <span class="notice-use"
                  >사용여부가 체크된 최대 5개의 영상만 홈페이지에
                  노출됩니다.</span
                >
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'MainVisualContentsList' }"
            class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickSubmit">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../../FileSelect.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect },
  name: "MainVisualContentsEdit",
  data() {
    return {
      filePc: null,
      fileMobile: null,

      title: "",
      subtitle: "",
      status: 1,

      attachments: null,
    };
  },
  created() {
    console.log("props id => ", this.id);
    console.log("props mainvisualcontents => ", this.item);

    this.title = this.item.title;
    this.subtitle = this.item.subtitle;
    this.status = this.item.status;

    this.attachments = this.item.attachments;
    console.log("attachment => ", this.attachments);
    // var index = 0 ;
    for (var attfile of this.attachments) {
      if (attfile.type == "mobile") {
        this.fileMobile = {
          name: attfile.originalname,
          filename: attfile.filename,
        };
      } else if (attfile.type == "pc") {
        this.filePc = {
          name: attfile.originalname,
          filename: attfile.filename,
        };
      }
    }
  },
  methods: {
    onSubmitModifyAttachFiles(next_menu) {
      let formData = new FormData();
      formData.append("owner_id", this.item.id);
      formData.append("board_name", "main_visual_contents");

      var keepObjects = [];
      var deleteObjects = [];

      // 주 파일 수정 여부 판단 board_name 에 따라서 fileMain fieldname 변경 필요
      if (this.filePc instanceof File) {
        formData.append("filepc", this.filePc);
      } else {
        if (this.filePc != null && Object.keys(this.filePc).length !== 0) {
          console.log("this.filePc is not File. but Object ");
          keepObjects.push(this.filePc); // 유지 되어야 하는 파일 정보로 기록 ( 삭제 파일을 가리기 위함. )
        } else {
          // this.fileMain is emptyp. default. 원래 파일이 없었음.
          console.log("원래 파일이 없었음.");
        }
      }

      if (this.fileMobile instanceof File) {
        formData.append("filemobile", this.fileMobile);
      } else {
        if (
          this.fileMobile != null &&
          Object.keys(this.fileMobile).length !== 0
        ) {
          console.log("this.fileMobile is not File. but Object ");
          keepObjects.push(this.fileMobile); // 유지 되어야 하는 파일 정보로 기록 ( 삭제 파일을 가리기 위함. )
        } else {
          // this.fileMain is empty. default. 원래 파일이 없었음.
          console.log("원래 파일이 없었음.");
        }
      }

      // files에 없고 attachments에 있는 파일은 삭제되어야 할 파일임.
      for (var initItem of this.attachments) {
        if (!keepObjects.find((el) => el.filename == initItem.filename)) {
          // 삭제하여야 할 대상임.
          console.log("삭제하여야 할 대상 => ", initItem);
          deleteObjects.push(initItem); // 초기에 첨부파일 정보로 내려준 데이터와 비교하여 남겨진것 이외에 삭제할것들 정보
        }
      }

      for (var delitem of deleteObjects) {
        console.log("files to deleted => ", delitem);
        formData.append("deleteAttachmentIdList[]", delitem.id);
      }

      this.$store
        .dispatch("req_create_attachments", formData)
        .then((res) => {
          console.log("attachment ret ", res.data);
          this.onModifyCompleted(next_menu);
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
    onClickSubmit() {
      console.log("in onClickSubmit ==>  title ", this.title);

      const payload = {
        id: this.item.id,
        title: this.title,
        subtitle: this.subtitle,
        status: this.status,
      };

      this.$store
        .dispatch("others/req_update_mainvisualcontents", payload)
        .then(() => {
          // 첨부파일 수정시 처리
          if (this.filePc instanceof File || this.fileMobile instanceof File) {
            this.onSubmitModifyAttachFiles("MainVisualContentsList");
          } else {
            this.$alert("수정하였습니다.").then(() => {
              this.$router.push({ name: "MainVisualContentsList" });
            });
          }
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
  },
};
</script>
<style scoped>
.notice-use {
  font-size: 16px;
  color: #ff4141;
}
</style>
