<template>
  <div>
    <section class="main-visual-contents-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>메인 비주얼 동영상 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>메인 비주얼 동영상 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">부제</label>
              </th>
              <td>
                <input
                  type="text"
                  id="sub-title"
                  name="sub-title"
                  v-model="subtitle"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">PC 동영상 파일 첨부</th>
              <td class="attached-file file-cell">
                <p v-if="filePc">{{ filePc.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="filePc"></file-select>
                <span>사이즈 : 1920 X 1280px / 용량 50MB (mp4) </span>
              </td>
            </tr>
            <tr>
              <th scope="row">MOBILE 동영상 파일 첨부</th>
              <td class="attached-file file-cell">
                <p v-if="fileMobile">{{ fileMobile.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMobile"></file-select>
                <span>사이즈 : 720 X 1040px / 용량 50MB (mp4) </span>
              </td>
            </tr>

            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="video-use"
                    name="video"
                    value="1"
                    v-model="status"
                  />
                  <label for="video-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="video-unuse"
                    name="video"
                    value="0"
                    v-model="status"
                  />
                  <label for="video-unuse">미사용</label>
                </span>
                <span class="notice-use"
                  >사용여부가 체크된 최대 5개의 영상만 홈페이지에
                  노출됩니다.</span
                >
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'MainVisualContentsList' }"
            class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../..//FileSelect.vue";

export default {
  components: { AppFooter, FileSelect },
  name: "MainVisualContentsCreate",
  data() {
    return {
      filePc: null,
      fileMobile: null,

      title: "",
      subtitle: "",
      status: 1,
    };
  },
  methods: {
    onClickCreate() {
      // console.log("in onClickCreate ==>  title ", this.title);
      // console.log("in onClickCreate ==>  subtitle ", this.subtitle);
      // console.log("in onClickCreate ==>  status ", this.status);

      // console.log("in onClickCreate ==>  filePc ", this.filePc);
      // console.log("in onClickCreate ==>  fileMobile ", this.fileMobile);

      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("subtitle", this.subtitle);
      formData.append("status", this.status);

      if (this.filePc != null && this.filePc != undefined)
        formData.append("filepc", this.filePc);
      if (this.fileMobile != null && this.fileMobile != undefined)
        formData.append("filemobile", this.fileMobile);

      this.$store
        .dispatch("others/req_create_mainvisualcontents", formData)
        .then((result) => {
          console.log("req_create_mainvisualcontents result => ", result);
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "MainVisualContentsList" });
          });
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
  },
};
</script>
<style scoped>
.notice-use {
  font-size: 16px;
  color: #ff4141;
}
</style>
