<template>
  <div>
    <section class="admin-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>관리자 계정 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>OTHERS</span>&nbsp;&gt;&nbsp;
            <span>관리자 계정 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="admin-level">레벨/권한</label>
              </th>
              <td>
                <span>

                  <select
                    name="admin-level"
                    id="admin-level"
                    v-model="admin_group"
                  >
                    <option
                      v-for="item in admin_groupList"
                      v-bind:key="item.id"
                      :value="item"
                    >
                      {{ item.id + ' / ' + item.team_name  }}
                    </option>
                  </select>


                </span>
              </td>
            </tr>

            <tr>
              <th scope="row"><label for="admin-id">아이디</label></th>
              <td class="admin-id">
                <input type="text" name="admin-id" id="admin-id" v-model="admin_id" readonly/>
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="admin-password">비밀번호</label></th>
              <td>
                <input
                  type="password"
                  name="admin-password"
                  id="admin-password"
                  v-model="admin_pw"
                />
              </td>
            </tr>
            <!-- <tr>
              <th scope="row"><label for="admin-password">비밀번호 확인</label></th>
              <td>
                <input
                  type="password"
                  name="admin-password"
                  id="admin-password"
                  v-model="admin_pw_confirm"
                />
              </td>
            </tr> -->
            <tr>
              <th scope="row"><label for="admin-name">이름</label></th>
              <td>
                <input type="text" name="admin-name" id="admin-name" v-model="name" />
              </td>
            </tr>
            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="video-use"
                    name="video"
                    value=1
                    v-model="status"
                  />
                  <label for="video-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="video-unuse"
                    name="video"
                    value=0
                    v-model="status"
                  />
                  <label for="video-unuse">미사용</label>
                </span>
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'AdminList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickSubmit">수정</button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "AdminEdit",
  data() {
    return {

      admin_groupList: [],
      admin_group: null ,
      admin_id : '',
      admin_pw : '',
      admin_pw_confirm : '',
      name : '',
      status: 1, 
    }
  },
  created() {

    this.admin_id = this.item.admin_id ; 
    this.name = this.item.name ; 
    this.status = this.item.status ; 

    this.$store.dispatch("others/req_admingroup_list"  )
    .then(( ret ) => {
      this.admin_groupList = ret.data ; 
      console.log( ' loadAdminGroupList ret => ',this.admin_groupList   ); 
      this.admin_group = this.admin_groupList.find( el => el.id == this.item.auth_group )
    })
    .catch((error) => {
      console.log("error => ", error);
    });

  },
  methods: {
    onClickSubmit() {
      console.log("in onClickSubmit ==>  title ", this.title);

      var payload = {
        id: this.item.id , 
        auth_group: this.admin_group.id , 
        admin_id: this.admin_id ,
        name: this.name,
        status: this.status ,
      };

      if( this.admin_pw != null && this.admin_pw != undefined && this.admin_pw.length > 0 ) {
        payload.admin_pw =  this.admin_pw ; 
      }

      console.log("in onClickSubmit ==>  ", payload );

      this.$store.dispatch("others/req_update_admin",  payload  )
      .then(( ) => {
        this.$alert("수정하였습니다.").then( () => {
            this.$router.push({ name: "AdminList" });
        });
      })
      .catch((error) => {
        console.log("error => ", error);
      });


    },

  },

};
</script>
<style lang="scss" scoped>
select,
input[id="admin-id"],
input[id="admin-password"],
input[id="admin-name"] {
  width: 230px !important;
}
</style>
